.nav-header {
    height: 5rem;
    background-color:white;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
}

.deznav {
    background-color:white;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
}

.header {
    height: 5rem;
    background-color:white;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
}

.content-body{
    background-color: #FCFCFC;
}

/* HOME */

.bordered-card{
    border: 1px solid #F1F1F1;
    box-sizing: border-box;
    border-radius: 8px;
}

.circle-1 {
    height: 25px;
    width: 25px;
    content: "";
    border-radius: 100%;
    transform: translate(-50%, -50%);
}

.card-bold-title{
    font-family: Montserrat;
font-style: normal;
font-weight: 800;
font-size: 32px;
line-height: 40px;
text-transform: uppercase;
color: #1A1A1A;
}

.btn { 
    border-radius: 8px;
}



/* Profile */
.jto-top-profile-item {
    position: absolute;
    top: 10%;
    left: 15%;
  }

  .profile-caption-1 figcaption {
    position: absolute;
    bottom: 65px;
    left: 0;
    right: 0;
}
.qr-svg{
    left:0;
    top:0; 
    width:100%;
    height:100%;
}

.qr-box{
    width:100px; 
    height:100px;
}


.resizable-content {
    min-height: 100px;
    min-width: 100px;
    resize: both;
    overflow: auto;
    max-height: fit-content;
    max-width: fit-content;
  }

  .form-control{
    border-radius: 0;
    background: #fff;
    border: 1px solid #9e9e9e;
    color: #6e6e6e;
  }

  .MuiOutlinedInput-root {
    width: 100%;
    border-radius: 0px !important;
}

.MuiOutlinedInput-root input {
    border-radius: 0;
}

.PhoneInputInput{
    border-radius: 0;
    background: #fff;
    border: 1px solid #6e6e6e;
    color: #6e6e6e;
    height: 56px;
    padding-left:15px ;
  }

  .form-control{
    border-radius: 0;
    background: #fff;
    border: 1px solid #6e6e6e;
    color: #6e6e6e;
  }

  .table thead th {
    border-bottom-width: 1px;
    font-size: 12px;
    font-weight: 500;
    padding: 10px 10px;
  }
  
  .table td {
    padding: 2px 5px;
    white-space: nowrap;
    margin: 0;
  }
  .table {
    font-size: 0.7rem !important;
  }